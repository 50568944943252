import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { UseFormReturnType, useForm } from '@mantine/form';

import { emailValidator, passwordValidator } from '../../utils';

import { SignUpRequest } from '../../@types/company';

interface SignUpFormValues
  extends Omit<
    SignUpRequest,
    | 'captcha'
    | 'locations'
    | 'sale_id'
    | 'reseller_id'
    | 'referral_staff_id'
    | 'hs_deal_id'
  > {
  locations: string[];
  password_confirmation?: string;
  tncAgreed?: boolean;
}

interface ISignUpFormContext {
  activeStep: number;
  previousStep: () => void;
  nextStep: () => void;
  form: UseFormReturnType<SignUpFormValues>;
}

const SignUpFormContext = createContext<ISignUpFormContext | undefined>(
  undefined,
);

export function useSignUpFormContext() {
  const signUpForm = useContext(SignUpFormContext);

  if (!signUpForm) {
    throw new Error(
      'useSignUpFormContext must be used within a SignUpFormProvider',
    );
  }

  return signUpForm;
}

export const SignUpFormProvider = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [activeStep, setActiveStep] = useState(0);

  const form = useForm<SignUpFormValues>({
    initialValues: {
      company_name: '',
      company_size: '3',
      // company_industry: '',
      locations: [],
      pcb_question: '',
      full_name: '',
      contact_number: '',
      email: '',
      password: '',
      password_confirmation: '',
      tncAgreed: false,
    },
    validate: values => {
      if (activeStep === 0) {
        return {
          company_name: !values.company_name
            ? 'Company name is required'
            : null,
          company_size: !values.company_size
            ? 'Company size is required'
            : null,
          // company_industry: !values.company_industry
          //   ? 'Company Industry is required'
          //   : null,
          locations: !values.locations.length
            ? 'At least one location is required'
            : null,
        };
      }

      if (activeStep === 1) {
        return {
          full_name: !values.full_name ? 'Full name is required' : null,
          contact_number: !values.contact_number
            ? 'Phone number is required'
            : null,
          email: emailValidator(values.email),
          password: passwordValidator(values.password),
          password_confirmation:
            values.password !== values.password_confirmation
              ? 'Passwords do not match'
              : null,
          tncAgreed: !values.tncAgreed
            ? 'You must agree to our Terms of Use and Privacy Policy'
            : null,
        };
      }

      return {};
    },
  });

  const nextStep = useCallback(() => {
    setActiveStep(currentStep => {
      if (form.validate().hasErrors) {
        return currentStep;
      }
      return currentStep < 3 ? currentStep + 1 : currentStep;
    });
  }, [form]);

  const previousStep = useCallback(() => {
    setActiveStep(currentStep => (currentStep > 0 ? currentStep - 1 : 0));
  }, []);

  const value = useMemo(
    () => ({ activeStep, previousStep, nextStep, form }),
    [activeStep, form, previousStep, nextStep],
  );

  return (
    <SignUpFormContext.Provider value={value}>
      {children}
    </SignUpFormContext.Provider>
  );
};
